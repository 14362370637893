// TODO: Determine whether or not to add back after removing bootstrap.
//@tailwind base;
@tailwind components;
@tailwind utilities;

.htmx-indicator{
@apply tw-hidden;
}

.htmx-request .my-indicator,
.htmx-request.my-indicator{
@apply tw-inline;
}
